import React from "react";
import "./sidebar.scss";
import { NavLink, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const SideBar = () => {
  const { currentUser } = useSelector((state) => state.user);

  const getInitials = (fullName) => {
    const nameParts = fullName.split(" ");
    let initials = "";
    for (let part of nameParts) {
      if (part.length > 0) {
        initials += part[0].toUpperCase();
      }
    }
    return initials;
  };

  return (
    <>
      <aside id="sidebar" className="sidebar">
        <div className="user">
          <span>{currentUser && getInitials(currentUser.name)}</span>
          <h5>{currentUser && currentUser.name}</h5>
          <p>{currentUser && currentUser.email}</p>
        </div>
        <hr />
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <NavLink className="nav-link" to="/dashboard">
              <i className="bi bi-grid"></i>
              <span>Dashboard</span>
            </NavLink>
            <NavLink className="nav-link" to="/cognition-analysis">
              <i className="bi bi-clipboard-data"></i>
              <span>Cognition Analysis</span>
            </NavLink>
            {/* <NavLink className="nav-link" to="/content-management">
              <i className="bi bi-card-list"></i>
              <span>Content Management</span>
            </NavLink> */}
            <NavLink className="nav-link" to="/content-allocation">
              <i className="bi-card-checklist"></i>
              <span>Content Allocation</span>
            </NavLink>
          </li>
        </ul>
      </aside>
      <Outlet />
    </>
  );
};

export default SideBar;
